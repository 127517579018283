import React from 'react'
import { AlbumUploadsContainer, FormLabel, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../MusicUploadsFormElements'
import { ModalLoadingScreen } from '../../LoadingScreen';
import FileUploader from '../../FileUploader';
import { FormWrapper } from '../../CustomForm/CustomFormElements';
import { Theme } from '../../WebsiteThemeElements';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';

const Page5 = ({videoFile, visualizerFile, setVideoFile, setVisualizerFile}) => {
  const {themeColors} = useWebsiteTheme()

  const setVideoDuration = (duration) => {
    setVideoFile(prev => {
      return {
        ...prev,
        duration: duration
      }
    })
  }
  const setVisualizerDuration = (duration) => {
    if(duration < 3 || duration > 8)
    {
    alert("Duration too long");
    setVisualizerFile()
  }else{
    setVisualizerFile(prev => {
      return {
        ...prev,
        duration: duration
      }
    })
    }
    
  }

  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Visuals</SongDetailsSectionTitle>
      <FormWrapper><FormLabel>Visualizer</FormLabel>
              <AlbumUploadsContainer  for={visualizerFile?.progress > 0 && !visualizerFile?.url ? "" : "visualizer"}>
                
                  {visualizerFile?.url && 
                    <SongDetailsText>${visualizerFile?.file?.name}</SongDetailsText>}
                    
                    {!visualizerFile?.url && !visualizerFile?.progress && <>
                    <SongDetailsText >Click here to add short visual file </SongDetailsText>
                    <SongDetailsText background={themeColors.secondaryColor}>○ Visualizer 3s to 8s video </SongDetailsText> 
                    <SongDetailsText background={themeColors.secondaryColor}>○ 9:16 ratio </SongDetailsText> 
                    <SongDetailsText background={themeColors.secondaryColor}>○ At least 720px tall (MP4)</SongDetailsText></>}
                
                {visualizerFile?.url && visualizerFile.progress === 100 && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                {visualizerFile?.progress > 0 && visualizerFile.progress < 100 && (
                    <>
                      <ModalLoadingScreen
                        transparent
                        isLoaded={visualizerFile.url ? true : false}
                        text={visualizerFile.progress < 100
                          ? `${visualizerFile?.progress.toFixed(2)}%`
                          : `Processing`}
                      />
                      
                    </>
                  )}
                <FileUploader
                  id="visualizer"
                  fileUpload={visualizerFile}
                  userType={"artists"}
                  setFileUpload={setVisualizerFile}
                  setDuration={setVisualizerDuration}
                  name="visualizer"
                  fileTypes={"video/mp4,video/x-m4v"}
                  acceptMultiple="false"
                />
              </AlbumUploadsContainer>
          </FormWrapper>

      <FormWrapper><FormLabel>Video File</FormLabel>
              <AlbumUploadsContainer  for={videoFile?.progress > 0 && !videoFile?.url ? "" : "video"}>
                <SongDetailsText>
                  {videoFile?.url && 
                    `${videoFile?.file?.name}`}
                    
                    {!videoFile?.url && !videoFile?.progress && `Click here to add video file (Video)`}
                </SongDetailsText>
                {videoFile?.url && videoFile.progress === 100 && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                {videoFile?.progress > 0 && videoFile.progress < 100 && (
                    <>
                      <ModalLoadingScreen
                        transparent
                        isLoaded={videoFile.url ? true : false}
                        text={videoFile.progress < 100
                          ? `${videoFile?.progress.toFixed(2)}%`
                          : `Processing`}
                      />
                      
                    </>
                  )}
                <FileUploader
                  id="video"
                  fileUpload={videoFile}
                  userType={"artists"}
                  setFileUpload={setVideoFile}
                  setDuration={setVideoDuration}
                  name="video"
                  fileTypes={"video/mp4,video/x-m4v,video/*"}
                  acceptMultiple="false"
                />
              </AlbumUploadsContainer>
          </FormWrapper>
      </SongDetailsSection>
  )
}

export default Page5