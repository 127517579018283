import React, { useEffect, useRef, useState } from "react";
import {
  CloseButton,
  FormInput,
  FormLabel,
  FormWrapper,
  MusicTitleInfoContainer,
  MusicUploadAlbumArt,
  MusicUploadContentTitle,
  MusicUploadDetailsContainer,
  MusicUploadInfoText,
  MusicUploadTitleArtContainer,
  MusicUploadTitleTextWrapper,
  SongArtCoverWrapper,
  SongDetailsContainer,
  SongDetailsSection,
  SongDetailsWrapper,
  SongInfoContainer,
  SongInfoTextWrapper,
  SongInfoWrapper,
  FromTextArea,
  SongDetailsSectionTitle,
  AlbumDetailsContainer,
  AlbumDetailsWrapper,
  AlbumDetailsSection,
  AlbumDetailsSectionTitle,
  FormTextArea,
  MusicUploadsButtonContainer,
  MusicUploadsButton,
  AlbumUploadsInput,
  AlbumArtPreview,
  AlbumArtPreviewContainer,
  SongDetailsText,
  AlbumUploadsContainer,
  ErrorWrapper,
} from "../MusicUploadsFormElements";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import SongDetails from "./SongDetails";
import { AlbumArtWrapper } from "../../MusicPage/MusicPageElements";
import { Theme } from "../../WebsiteThemeElements";
import { hexToRgba } from "../../HexToRGBA";
import ImageUtils from "../../../utils/ImageUtils";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { Fade, Zoom } from "react-reveal";
import CurrencySelector from "../../CurrencySelector";
import ToggleButton from "../../ToggleButton";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";

import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import {
  getDownloadUrl,
  storage,
  storageRef,
  timestamp,
  uploadBytes,
} from "../../Authorization/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import {
  containsObject,
  getObjectLength,
  getUniqueListBy,
  newlineFormatter,
} from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  ARTIST_SEARCH_TYPES,
  TRANSACTION_OPTIONS,
} from "../../../utils/Enum";
import useArtist from "../../../hooks/useArtist";
import useMusic from "../../../hooks/useMusic";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import PageIndexer from "../../PageIndexer";
import { useAlert } from "../../Alert/AlertContext";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../../Modal";
import QRCodeForm from "../../QRCodeForm";
import ScrollToTop from "../../ScrollToTop";
import NFTStorageTokenForm from "../NFTStorageTokenForm";
import NFTStorageSonarAPIKeyForm from "../../NFTStorageSonarAPIKeyForm";
import { saveAlbumAsDraft } from "../../../utils/dbUtils/draftsUtils";
import Page4 from "./pages/Page4";
import { UnavailableFeature } from "../../MenuOptions";
import useRecordLabel from "../../../hooks/useRecordLabel";
import { deleteObject } from "firebase/storage";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";
import Page5 from "./pages/Page5";
import { Small } from "../../QRCodeForm/QrCodeFormElements";
import { isrcReg } from "../../../utils/Regex";

function AlbumUploads({info}) {
  const {themeColors} = useWebsiteTheme()
  const { currentUser, currentProfile } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const {getRecordLabelArtists} = useRecordLabel({})
  const location = useLocation()
  const { addAlert } = useAlert();
  const { uploadAlbum, searchGenres, deleteDraft } = useMusic();
  const { modalContent, isOpen, toggleModal, noCloseModal, closeModal, openModal } = useModal();
  
  const { defaultAlbumArt } = ImageUtils();
  const {goTo} = ScrollToTop()
  const navigate  = useNavigate()
  const genreRef = useRef();
  const aboutRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");

  const [showDetails, setShowDetails] = useState(false);
  const [songs, setSongs] = useState(info?.songs ? info?.songs : []);
  const [isLoaded, setLoaded] = useState(false);
  const [openSongs, setOpenSongs] = useState([]);
  const [pageNumber, setPageNum] = useState(1);
  const [songUploadTasks, setSongUploadTasks] = useState([]);
  const [albumUploadInfo, setAlbumUploadInfo] = useState()
  const [userRoyalties, setUserRoyalties] = useState(info?.userRoyalties ? info?.userRoyalties : {
    percentage: 10,
    shares: 100,
    price: 1,
    shareReserve: 0,
    ticker: "",
    currency: "XRP",
  })
  const [albumInfo, setAlbumInfo] = useState({
    currency: info?.currency ? info?.currency : "XRP",
    price: info?.isDownloadable ? info?.price: 0,
    albumArt: info?.albumArt ? info?.albumArt :{},
    artists: info?.artists ? info?.artists :[],
    songs: info?.songs ? info?.songs :[],
    userArtist: info?.userArtist ? info?.userArtist : [],
    duration: info?.duration ? info?.duration :0,
    visualizerFile: info?.visualizerFile ? info?.visualizerFile : {},
    mainGenre: info?.mainGenre ?info?.mainGenre : undefined,
    isDownloadable: info?.isDownloadable ? info?.isDownloadable :false,
    isStreamable: info?.isStreamable ? info?.isStreamable :true,
    isUserRoyalties: info?.isUserRoyalties ? info?.isUserRoyalties : false,
    isMintable: info?.isMintable ? info?.isMintable :false,
    isPreRelease: info?.isPreRelease ? info?.isPreRelease : false,
    isVisualizer: info?.isVisualizer ? info?.isVisualizer : false,
    releaseDate: info?.releaseDate ? info?.releaseDate :undefined,
    isSubscription: info?.isSubscription ? info?.isSubscription :false,
    albumName: info?.albumName ? info?.albumName :"",
    license: info?.license ? info?.license :"ARR",
    uploadedBy: currentUser?.uid,
    errors: undefined
  });
  const [cancelAlbumArtUpload, setCancelAlbumArtUpload] = useState(false);
  const [artistSearch, setArtistSearch] = useState();
  const [artistEditing, setArtistEditing] = useState(false);
  const [filteredGenres, setGenreFilter] = useState([]);
  const [genre, setGenre] = useState("");
  const [submitting, setSubmit] = useState(false)
  const [albumId, setAlbumId] = useState(info?.albumId ? info?.albumId : undefined)
  const [requestRelease, setReleaseRequest] = useState(false)
  const [currentArtist, setCurrentArtist] = useState({});
  const [prevChanges, setPrevChanges] = useState({})
  const [artists, setArtists] = useState(info?.artists ? info?.artists :[]);
  const [hideButtons, setHideButtons] = useState(false)
/*   const [videoFile, setVideoFile] = useState(info?.videoFile ? info?.videoFile :{});
  const [visualizerFile, setVisualizerFile] = useState(info?.visualizerFile ? info?.visualizerFile :{});
 */
//console.log(songs.length)

  useEffect(() => {
    if (!isLoaded) {
      setMainArtist();
    }
    return () => {}
  }, []);

  useEffect(() => {
    if(!window.location.href.includes('/edit/album')){
      window.addEventListener('beforeunload', function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
    }
    
    return () => {}
  },)

  useEffect(() => {
    getAlbumDuration();
    return () => {}
  }, []);


useEffect(() => {
   if(getObjectLength(albumInfo?.albumArt) > 0 || albumId?.length > 0 || songs?.some((s,index) => 
      getObjectLength(s?.songUpload) > 0
    )){
      
      autoSave()
    }
    return () => {}
  }, [albumInfo]) 

  const autoSave = () => {
    /* const interval = setTimeout(() => {
     if(window.location.pathname === location.pathname){
      updateAlbumInfo();}
    }, 25000);
    return () => clearInterval(interval); */
  }; 

  const cancelMusicUpload = () => {
    let album = {
      ...albumInfo,
      albumArt: albumInfo?.albumArt,
      artists: artists,
      albumId: albumId,
      duration: getAlbumDuration(),
      price: albumInfo?.price,
      releaseDate : albumInfo?.releaseDate ? albumInfo?.releaseDate : undefined,
    }
    if(albumId?.length > 0 || album?.albumName?.length > 0 || getObjectLength(album?.albumArt) > 0 || songs?.some((s,index) => 
    getObjectLength(s?.songUpload) > 0
  ) ){
        if(!isOpen){
          toggleModal()
        }
        modalContent(<>
          <OptionModal heading={"Are you sure you want to leave uploading"}>
            <HoverOption onClick={() => {
              navigate(-1)
              toggleModal()
              modalContent()
              }}>
              Leave
            </HoverOption>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
            }}>
              Cancel
            </HoverOption>
            {albumId?.length > 0 && <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)
              deleteDraft(albumId)
            }}>
              Leave & Delete Draft
            </HoverOption>}
          </OptionModal>
        </>)
        return
    }
    navigate(-1)
  }

  const updateAlbumInfo = () => {
    let album = {
      ...albumInfo,
      artists: artists,
      albumId: albumId,
      licensing: albumInfo?.license,
      visualizerFile: albumInfo?.visualizerFile,
      duration: getAlbumDuration(),
      type: "draft",
      price: albumInfo?.price,
      releaseDate : albumInfo?.releaseDate ? albumInfo?.releaseDate : undefined,
      songs: songs
    }
    delete album?.license
   
      if(JSON.stringify(prevChanges) !== JSON.stringify(album)){
        setHideButtons(true)
        let newPrev = albumInfo
        return saveAlbumAsDraft(album).then(data => {
          setHideButtons(false)
          if(data && !data?.error){
            setPrevChanges(newPrev)
            setAlbumId(data);
              const url = new URL(window.location.href);
              const params = new URLSearchParams(url.search);
              params.set("id", data)
              if(window.location.pathname === location.pathname){
              navigate(`${location.pathname}?${params.toString()}`,{
                replace:true},
              );
              }
            /* addAlert({
              title: 'Auto Saved!',
              message: 'Album saved to drafts',
              type: ALERT_TYPES.INFO
            }) */
            return
          }else{
            setHideButtons(false)
          }
        }).catch(err => {
          console.error(err)
          setHideButtons(false)
        })
      }
  }

  const cancelUpload = () => {
    addAlert({
      title: 'Album Upload',
      message: 'Upload Cancelled',
      type: ALERT_TYPES.WARNING
    })
    modalContent()
    toggleModal()
    setSubmit(false);
    setReleaseRequest(false);
    setHideButtons(false)

  };

  const onSuccess = (id) => {
    setSubmit(true);
    modalContent();
    toggleModal();
    setReleaseRequest(false);
    setHideButtons(false)
    setAlbumId(id);
    setAlbumInfo(prev => {return {...prev, errors: { message: "Album Successfully Released" }}});
  };

  const onFailure = () => {
    modalContent();
    toggleModal();
    setReleaseRequest(false);
    setHideButtons(false)
    setSubmit(false);
    setAlbumInfo(prev => {return {...prev, errors: { message: "Album Failed to Release" }}});
  };

  const releaseAlbum = async (type) => {
    let album = albumInfo;
    setHideButtons(true)
    
if (songs?.length > 0) {
  switch (type) {
    case "draft":
     try{
      let errors = albumInfo?.errors
      if(errors?.message){
      delete errors.message}
setAlbumInfo(prev => {return {...prev, errors}}) 
      
      album = {
        ...album,
        songs: songs,
        artists: artists,
        albumId: albumId,
        duration: getAlbumDuration(),
        type: "draft",
        price: albumInfo?.price,
        releaseDate : album?.releaseDate ? album?.releaseDate : undefined,
      }
      addAlert({
        title: 'Saving Draft',
        message: 'Saving album to drafts...',
        type: ALERT_TYPES.INFO
      })
      return saveAlbumAsDraft(album).then((data) => {
        setHideButtons(false)
        if (data && !data?.error) {
          setAlbumId(data);
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);
          params.set("id", data)
          navigate(`${location.pathname}?${params.toString()}`,{
            replace:true},
          );
          setPrevChanges(album)
          addAlert({
            title: 'Draft Saved',
            message: `Album saved to drafts`,
            type: ALERT_TYPES.SUCCESS
          })
          return
        }else{
          setHideButtons(false)
          setAlbumInfo(prev => {return {...prev, errors: {...prev.errors, message: "Album Failed to Save as Draft" }}});

        }
      })
      .catch((err) => {
        console.error(err)
        setHideButtons(false)
        setAlbumInfo(prev => {return {...prev, errors: {...prev.errors, message: "Album Failed to Save as Draft" }}});
      });
    }catch(err){
      console.error(err)
      setHideButtons(false)
      addAlert({
                title: 'Draft Error',
                message: 'Failed to save to drafts',
                type: ALERT_TYPES.DANGER
              })
    }

      break;
    case "publish":
      let songErrors = []
        for (let i = 0; i < albumInfo?.songs?.length; i++) {
          let song = albumInfo.songs[i];
          songErrors[i] = await validateSongInfo(song, i);
        }
        openModal()
        noCloseModal(<ModalLoadingScreen text={"Validating Upload. Please Wait..."}/>)
      if (validateAlbumInfo() &&
        albumInfo?.songs?.length > 0 &&
          !songErrors.some((s) => s === false)
      ) {
        album = {
          ...album,
          albumId: albumId,
          sender: currentUser?.uid,
          memo: `${currentUser?.uid} is submitting the album ${album?.albumName} to be released`,
          instruction: `${currentUser?.uid} is submitting the album ${album?.albumName} to be released`,
          albumArt: albumInfo?.albumArt?.url,
          albumArtRef: albumInfo?.albumArt?.ref,
          artists: artists,
          userRoyalties: albumInfo?.isUserRoyalties ? userRoyalties : undefined,
          duration: getAlbumDuration(),
          visualizer: getObjectLength(album?.visualizerFile) > 0 ? {
            url: album?.visualizerFile?.url,
            duration: album?.visualizerFile?.duration,
            ref: album?.visualizerFile?.ref
          } : undefined,
          type: "publish",
          price: albumInfo?.price,
          releaseDate : album?.releaseDate ? album?.releaseDate : new Date()?.toISOString().split('T')[0],
          displayDate : album?.releaseDate ? new Date(album?.releaseDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
          songs: songs.map(s => {
            return filterSongInfo(s)
          })
        }
      
        noCloseModal(<>  <QRCodeForm
          heading={`Release of ${albumInfo?.albumName} by ${artists[0]?.artistName}`}
          body={album}
          cancelQr={cancelUpload}
          onSuccess={onSuccess}
          onFail={onFailure}
          type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.ALBUM}
        ></QRCodeForm></>)
      }else{
        closeModal()
      modalContent()
      setHideButtons(false);
      setAlbumInfo(prev => {return {...prev, errors: {...prev.errors,  message: "Album failed to upload. Please review information" }}});
      }
      break;
      
    default:
      break;
  }
  return
}else{
  closeModal()
      modalContent()
  setHideButtons(false)
  addAlert({
  title: type === "publish" ? 'Upload Error' : 'Save Error',
  message: type === "publish" ? 'Cannot upload an album without any tracks' : 'Cannot save an album without any tracks',
  type: ALERT_TYPES.DANGER
})
}


    
  };



  const getAlbumDuration = () => {
    let duration = albumInfo?.duration ? albumInfo?.duration : 0;

    if (songs?.length === 0) {
      duration = 0;
    }
    if (songs?.length > 0) {
      duration = 0;
      for (let i = 0; i < songs.length; i++) {
        if (songs[i]?.duration) {
          duration = duration + songs[i]?.duration;
        }
      }
    }
    return duration
  };

  
  const setMainArtist = async () => {
    if(currentProfile === 'label'){
     if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) === 0 && !info?.userArtist){
      openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        console.log(labelArtists)
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setAlbumInfo((prev) => {
                    return {
                      ...prev,
                      artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                      userArtist: {
                        ...artist,
                        exists: true,
                      },
                    };
                  });
                  setArtists((prev) => {
                  return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
                });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
      
     
    }else{
      if (info?.userArtist){
        if(artists?.length > 0 &&
        !containsObject(artists, { ...info?.userArtist, exists: true })
      ) {
        setAlbumInfo((prev) => {
          return {
            ...prev,
            artists: getUniqueListBy([{ ...info?.userArtist, exists: true }], "exists"),
            userArtist: {
              ...info?.userArtist,
              exists: true,
            },
          };
        });
        setArtists((prev) => {
        return getUniqueListBy([{ ...info?.userArtist, exists: true }, ...prev], "exists");
      });
      }
    }
      else{
        if(artists?.length > 0 && getObjectLength(albumInfo?.userArtist) > 0 &&
          !containsObject(artists, { ...albumInfo?.userArtist, exists: true })
        ) {
          setAlbumInfo((prev) => {
            return {
              ...prev,
              artists: getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }], "exists"),
              userArtist: {
                ...albumInfo?.userArtist,
                exists: true,
              },
            };
          });
          setArtists((prev) => {
          return getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }, ...prev], "exists");
        });
        }
        if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) > 0){
          setAlbumInfo((prev) => {
            return {
              ...prev,
              artists: getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }], "exists"),
              userArtist: {
                ...albumInfo?.userArtist,
                exists: true,
              },
            };
          });
          setArtists((prev) => {
          return getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }, ...prev], "exists");
        });
        }
        if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) === 0){
          openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        console.log(labelArtists)
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setAlbumInfo((prev) => {
                    return {
                      ...prev,
                      artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                      userArtist: {
                        ...artist,
                        exists: true,
                      },
                    };
                  });
                  setArtists((prev) => {
                  return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
                });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
        }
      }
    }
    setLoaded(true);
  }else{
    return await searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, currentUser?.uid).then(
      (artist) => {
        setLoaded(true);
        if (artists?.length === 0) {
          //console.log('doing this')
          setAlbumInfo((prev) => {
            return {
              ...prev,
              artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
              userArtist: {
                ...artist,
                exists: true,
              },
            };
          });
          
          setArtists(getUniqueListBy([{ ...artist, exists: true }], "exists"));
          return
        }
        if (
          artists?.length > 0 &&
          containsObject(artists, { ...artist, exists: true })
        ) {
          setAlbumInfo((prev) => {
            return {
              ...prev,
              artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
              userArtist: {
                ...artist,
                exists: true,
              },
            };
          });
          setArtists((prev) => {
          return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
        });
        return
        }
        
      }
      
    );
    }
    
  };

  const setCreativeCommons = (id) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        license: id,
      };
    });
  };

  const addTrack = () => {
    if(albumInfo?.isPreRelease && albumInfo?.releaseDate){    
    return setSongs((prev) => 
    [...prev,
      {
           currency: albumInfo?.isDownloadable
             ? albumInfo?.currency
             : "XRP",
           albumArt: albumInfo?.albumArt,
           artists: artists,
           duration: 0,
           isPreRelease: albumInfo?.isPreRelease,
           releaseDate: albumInfo?.releaseDate ? albumInfo?.releaseDate : new Date().toISOString().split('T')[0],
           isDownloadable: albumInfo?.isDownloadable,
           mainGenre: albumInfo?.mainGenre,
           isStreamable: albumInfo?.isStreamable,
           isMintable: albumInfo?.isMintable,
           isSubscription: albumInfo?.isSubscription,
           uploadedBy: currentUser.uid,
           license: albumInfo?.license,
         }
      
       ]);
      // console.log(songs)
  /*   if(s?.length === newLength){
      setSongs((prev) => 
      [...prev,
        {
             currency: albumInfo?.isDownloadable
               ? albumInfo?.currency
               : "WYM",
             albumArt: albumInfo?.albumArt,
             artists: artists,
             duration: 0,
             isDownloadable: albumInfo?.isDownloadable,
             isStreamable: albumInfo?.isStreamable,
             isMintable: albumInfo?.isMintable,
             isSubscription: albumInfo?.isSubscription,
             uploadedBy: currentUser.uid,
             license: albumInfo?.license,
           }
        
         ]);

    } */
}else{
  addAlert({  title: 'Release Date',
    message: 'Cannot add tracks without a pre release date',
    type: ALERT_TYPES.DANGER
  })
}};

  const  validateAlbumInfo = () => {
    let albumErrors = {}
    if(!albumInfo?.mainGenre || getObjectLength(albumInfo?.mainGenre) === 0){
      albumErrors = {
        ...albumErrors,
        genre:"Select album genre"
      }
    }
    if(artists?.length === 0){
      albumErrors = {
        ...albumErrors, 
        genre: "Select album artist"}
    }
    if((albumInfo?.isPreRelease && (new Date(albumInfo?.releaseDate).getTime() <= new Date()?.getTime() || !albumInfo?.releaseDate )) || (!albumInfo?.isPreRelease && (new Date(albumInfo?.releaseDate).getTime() > new Date()?.getTime())) ){
      albumErrors = {
        ...albumErrors, 
        releaseDate: "Select a valid release date"}
    } 
    if(albumInfo?.albumName?.trim() === ""){
      albumErrors = {
        ...albumErrors,
        albumName: "Cannot upload album without a title"
      }
    }
    if((albumInfo?.visualizerFile?.progress > 0 && albumInfo?.visualizerFile?.progress < 100)){
      albumErrors = {
        ...albumErrors,
        visuals: "Album visualizer still uploading..."
      }
    }
    if(!albumInfo?.albumArt || getObjectLength(albumInfo?.albumArt) === 0 || (!albumInfo?.albumArt?.url) ){
      albumErrors = {
        ...albumErrors, 
        albumArt: "Album artwork required"
      }
    }

    if (albumInfo?.isUserRoyalties && getObjectLength(userRoyalties) > 0) {
      let royaltyErrors = {};
      if (userRoyalties?.ticker.trim().length < 3) {
        royaltyErrors = {
          ...royaltyErrors,
          ticker: "Invalid Ticker. Please enter a valid tick. min 3 characters",
        };
      }
      if (userRoyalties.percentage < 10 || userRoyalties.percentage > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          percentage:
            "Invalid royalty share percentage. please input value between 10% - 50%",
        };
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if (getObjectLength(royaltyErrors) > 0) {
        albumErrors = {
          ...albumErrors,
          userRoyalty: royaltyErrors,
        };
      }
    }

    if(getObjectLength(albumErrors) > 0){
      setAlbumInfo(prev => {return {
        ...prev,
        errors: albumErrors
      }})
      addAlert({
        title: "Upload Error",
        type: ALERT_TYPES.WARNING,
        message: "Unable to upload album. Please update album information"
      })
      return false
    }else{
      return true
    }
    
  }

  const validateSongInfo = (song, index) => {
    let vErrors = {};
    if ((song?.songName && song.songName?.trim() === "") || !song.songName) {
      vErrors = {
        ...vErrors,
        songName: "You can't release a song without a name",
      };
    }
    if (
      !song.songUpload ||
      getObjectLength(song?.songUpload) === 0 ||
      !song.songUpload?.url
    ) {
      vErrors = {
        ...vErrors,
        songUpload: "Upload a song before releasing",
      };
    }

    if(song?.isrcType !== 'auto' && !isrcReg.test(song?.isrc)){
      vErrors = {
        ...vErrors,
        isrc: "invalid ISRC code. Enter a valid ISRC or choose auto-generate option" ,
      };
    }

    if(((song?.visualizerFile?.progress > 0 && song?.visualizerFile?.progress < 100) && !song?.visualizerFile?.url) ||
    ((song?.videoFile?.progress > 0 && song?.videoFile?.progress < 100) && !song?.videoFile?.url))
      {
      vErrors = {
        ...vErrors,
        visuals: "visuals still uploading..."
      }
    }

    if (
      (song?.isStreamable && getObjectLength(song?.payoutArtists) > 0 &&
      song?.payoutArtists?.some((artist) => artist.payoutPercentage === 0)) || (song?.isStreamable && getObjectLength(song?.payoutArtists) === 0)
    ) {
      vErrors = {
        ...vErrors,
        payoutArtists: "Please enter payout percentage for Artists"};
    }
    if (getObjectLength(song?.payoutArtists) > 0 && song?.payoutArtists?.balance > 100) {
      vErrors = {
        ...vErrors,
        payoutArtists:
        "payouts exceed 100%. please redistribute payouts"};
    }
    if (!song?.mainGenre || getObjectLength(song?.mainGenre) === 0 || !song?.mainGenre?.id) {
      vErrors = {
        ...vErrors,
        genre: "Select a main genre",
      };
    }
    /* if((song?.isPreRelease && (new Date(song?.releaseDate).getTime() <= new Date().getTime() || !song?.releaseDate)) || (!song?.isPreRelease && (new Date(song?.releaseDate).getTime() > new Date().getTime())) ){
      vErrors = {
        ...vErrors,
        releaseDate: song?.isPreRelease ? "Enter valid future release date" : "Enter valid release date" ,
      };
    } */

    if (song?.isUserRoyalties && getObjectLength(song?.userRoyalties) > 0) {
      let royaltyErrors = {};
      if (song?.userRoyalties?.ticker.trim().length < 3) {
        royaltyErrors = {
          ...royaltyErrors,
          ticker: "Invalid Ticker. Please enter a valid tick. min 3 characters",
        };
      }
      if (song?.userRoyalties.percentage < 10 || song?.userRoyalties.percentage > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          percentage:
            "Invalid royalty share percentage. please input value between 10% - 50%",
        };
      }
      if (song?.userRoyalties.shareReserve < 0 || song?.userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if (getObjectLength(royaltyErrors) > 0) {
        vErrors = {
          ...vErrors,
          userRoyalty: royaltyErrors,
        };
      }
    }

    if (getObjectLength(vErrors) > 0) {
      setAlbumInfo((prev) => {
        return {
          ...prev,
          songs: [
            ...prev?.songs.map((s, i) => {
              if (index === i) {
                return { ...s, errors: vErrors };
              }
              else {
                return s
              }
            }),
          ],
        };
      });
      openSongInfo(index);
      return false;
    }else {
      return true;
    }
  };

  const filterSongInfo = (song) => {
    return {
      songName: song?.songName.trim(),
      price: song?.isDownloadable ? song.price : undefined,
      isrc: song?.isrc.trim(),
      artists: song.artists,
      featuredArtists: song?.featArtists,
      songCredits: {
        composers: song?.composers,
        producers: song?.producers,
        writers: song?.writers,
      },
      genre: song.mainGenre,
      subGenres: song.subGenres,
      isStreamable: song.isStreamable,
      isUserRoyalties: song?.isUserRoyalties,
      userRoyalties: song?.isUserRoyalties ? song?.userRoyalties : undefined,
      isDownloadable: song.isDownloadable,
      isSubscription: song.isSubscription,
      isPreRelease: albumInfo?.isPreRelease ? song?.isPreRelease : undefined,
      isPreview: albumInfo?.isPreRelease ? song?.isPreview : false,
      isCover: song.isCover,
      currency: song.isDownloadable
        ? song.currency
          ? song?.currency
          : "WYM"
        : undefined,
      isMintable: song.isMintable,
      explicit: song?.isExplicit ,
      royalties: song?.payoutArtists,
      songUrl: song?.songUpload?.url,
      mp3Url: song?.songUpload?.mp3Url,
        mp3Ref: song?.songUpload?.mp3Ref,
        
      duration: song?.duration ? song?.duration : song?.songUpload?.duration,
      video: getObjectLength(song?.videoFile) > 0 ? {
        url: song?.videoFile?.url,
        duration: song?.videoFile?.duration,
        ref: song?.videoFile?.ref} : undefined,
      videoResolutions: getObjectLength(song?.videoFile) > 0 && song?.videoFile?.videoResolutions?.length > 0  ? song?.videoFile?.videoResolutions : undefined,
      visualizer: getObjectLength(song?.visualizerFile) > 0 ? {
        url: song?.visualizerFile?.url,
        duration: song?.visualizerFile?.duration,
        ref: song?.visualizerFile?.ref} : undefined,
      songRef: song?.songUpload?.ref,
      albumArt: albumInfo?.albumArt.url,
      albumArtRef: albumInfo?.albumArt.ref,
      about: song?.about.trim() !== "" ? song?.about.trim() : undefined ,
      uploadedBy: currentUser?.uid,
      songId: song?.songId,
      licensing: song?.license ? song?.license : albumInfo?.license,
      lyrics: song?.lyrics?.trim() !== '' ? song?.lyrics?.trim(): undefined,
      releaseDate: albumInfo?.isPreRelease ? song?.releaseDate ? song?.releaseDate : albumInfo?.releaseDate ? albumInfo?.releaseDate : undefined : undefined,
      displayDate: albumInfo?.isPreRelease ? song?.releaseDate ? new Date(song?.releaseDate).toISOString() : new Date(albumInfo?.releaseDate).toISOString(): new Date().toISOString(),
    }
  }

  const handleInput = (e) => {
    switch (e.target.name) {
      case "title":
        setAlbumInfo((prev) => {
          return { ...prev, albumName: e.target.value };
        });
        break;
      case "album price":
        setAlbumInfo((prev) => {
          return { ...prev, price: e.target.value };
        });
        if(e.target.value[0] === "0"){
          setAlbumInfo((prev) => {
            return { ...prev, price: e.target.value.substring(1,e.target.value.length) };
          })
         }else{
           setAlbumInfo((prev) => {
            return { ...prev, price: e.target.value };
          })
         }
         
/*          if(!isNaN(e.target.value) && e.target.value > 0){
           delete errs.price
           setErrors(errs)
           return
         } */
         if(e.target.value?.length === 0){
           
           setAlbumInfo((prev) => {
            return { ...prev, price: 0 };
          })
         }
         if(isNaN(e.target.value)){
         /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */

           setAlbumInfo((prev) => {
            return { ...prev, price: parseFloat(e.target.value) };
          })
           /* setErrors(err) */
           return
         }
        break;

      case "album genre":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          searchGenres(e.target.value).then((g) => {
            setGenreFilter(g.genres);
          });
        } else {
          setGenreFilter();
        }
        setGenre(e.target.value.trim());
        break;

      case "album releaseDate":
        setAlbumInfo((prev) => {
          return { ...prev, releaseDate: e.target.value };
        });
        break;
      case "album about":
        setAlbumInfo((prev) => {
          return { ...prev, about: newlineFormatter(e.target.value) };
        });
        break;
      case "albumArt":
        const currArt = albumInfo?.albumArt;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setAlbumInfo((prev) => {
              return {
                ...prev,
                albumArt: currArt,
              };
            });
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setAlbumInfo((prev) => {
                  return {
                    ...prev,
                    albumArt: {
                      ...prev.albumArt,
                      file: undefined,
                    },
                  };
                });
                return false;
              }

              //let artBlob = convertFileToBytes(e.target.files[0])
              let imageFileName;
              if (!albumInfo?.albumArt?.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
              } else {
                imageFileName = `${albumInfo?.albumArt?.name.split('.')[0]}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                } `;
              }
              setAlbumInfo((prev) => {
                return {
                  ...prev,
                  albumArt: {
                    ...prev?.albumArt,
                    name: imageFileName,
                  },
                };
              });
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `artists/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setAlbumInfo((prev) => {
                    return {
                      ...prev,
                      albumArt: {
                        ...prev?.albumArt,
                        progress: progress,
                      },
                    };
                  });

                  if (cancelAlbumArtUpload) {
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                    } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                    }
                  }
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      if (cancelAlbumArtUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      break;
                    default:
                      if (cancelAlbumArtUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: currArt,
                        };
                      });
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: currArt,
                        };
                      });
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: {
                            ...prev?.albumArt,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          },
                          songs: prev.songs?.map((s) => {
                            return {
                              ...s,
                              albumArt: downloadURL,
                              albumArtRef: uploadTask.snapshot.ref.fullPath,
                            };
                          }),
                        };
                      });
                      updateAlbumInfo()
                    }
                  );
                }
              );
            };
          };
        }
        break;
      default:
        break;
    }
  };

  const handleToggle = (type) => {
    switch (type) {
      case "explicit":
        let explicit = !albumInfo?.isExplicit ;
        setAlbumInfo((prev) => {
          return { ...prev, isExplicit: explicit };
        });
        break;
      case "streamable":
        let streamable = !albumInfo?.isStreamable;
        if (streamable) {
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isStreamable: streamable };
        });
        break;

        case "schedule":
        let preRelease = !albumInfo?.isPreRelease;
        setAlbumInfo((prev) => {
          return { ...prev, isPreRelease: preRelease };
        });
        break;

      case "downloadable":
        let downloadable = !albumInfo?.isDownloadable

          if (downloadable) {
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isDownloadable: downloadable };
        });
        break;
      case "visualizer":
        let visualizer = !albumInfo?.isVisualizer
          setAlbumInfo((prev) => {
            return { ...prev, isVisualizer: visualizer };
          });
        
        break;
      case "subscription":
        let subscription = !albumInfo?.isSubscription
        
          if (subscription) {
          setAlbumInfo((prev) => {
            return { ...prev, isDownloadable: false, isStreamable: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isSubscription: subscription };
        });
        break;
      case "mintable":
        let mintable = !albumInfo?.isMintable 
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  /*setAlbumInfo((prev) => {
                    return { ...prev, isMintable: true };
                })
                 toggleModal()
                modalContent() */
                  //modalContent(< UnavailableFeature/>)
                   modalContent(<>
                  <LoadingScreen transparent={true}/></>)
                  await validateNFTStorageKey(albumInfo?.userArtist?.artistId).then(data => {
                    if(data){
                      setAlbumInfo((prev) => {
                        return { ...prev, isMintable: true };
                      });
                      toggleModal()
                          modalContent()
                    }else{
                      modalContent(<>
                      <OptionModal heading={"IPFS API Key Not Found"}>
                        <HoverOption onClick={() => {
                          modalContent(<>
                            <NFTStorageTokenForm artistId={albumInfo?.userArtist?.artistId} currentUser={currentUser?.uid} setMintable={(value) => {
                              setAlbumInfo((prev) => {
                                return { ...prev, isMintable: value };
                              });
                            }}/>
                          </>)
                        }}>
                          Add API Key
                        </HoverOption>
                        <HoverOption onClick={() => {
                          modalContent(<NFTStorageSonarAPIKeyForm artistId={albumInfo?.userArtist?.artistId} currentUser={currentUser?.uid} onSuccess={() => {
                            setAlbumInfo((prev) => {
                            return { ...prev, isMintable: true };
                          })}}/>)
                        }}> Use Sonar Muse API Key</HoverOption>
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                         Cancel
                        </HoverOption>
                      </OptionModal>
                    </>)
                       
                    }
                  }).catch(err => {
                    setAlbumInfo((prev) => {
                      return { ...prev, isMintable: false };
                    });
                    modalContent(<>
                      <OptionModal heading={"Something Went Wrong. Please try again"}>
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                          Close
                        </HoverOption>
                      </OptionModal>
                    </>)
                  })
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setAlbumInfo((prev) => {
            return { ...prev, isMintable: false };
          });
        } 
    
       break;
       case "royalties":
        let royalties = !albumInfo?.isUserRoyalties;
        if (albumInfo?.isUserRoyalties === false) {
          openModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                closeModal();
                modalContent();
              }}
              heading={"Enable User Royalties Share"}
              additionalText={
                "Allow your supporters to invest and earn from your music sales and royalty streams."
              }
            >
              <Small>Note: Once enabled, it cannot be disabled.</Small>
              <HoverOption
                onClick={() => {
                  if (royalties) {
                    setAlbumInfo(prev => {
                      return {
                        ...prev,
                        isUserRoyalties: true,
                        isSubscription: false,
                        isStreamable: true
                      }
                    })
                  }
                 
                  closeModal();
                  modalContent();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setAlbumInfo(prev => {
            return {
              ...prev,
              isUserRoyalties: false
            }
          })
        }
        /*  let royalties = !isUserRoyalties
          setIsUserRoyalties(royalties)
          if(royalties){
          setSubscription(false);
          setStreamable(true);}
          if(isDownloadable && royalties){
            setCurrency("XRP")
          } */
        break;

      default:
        break;
    }
  };

  const setCurrency = (currency) => {
    setAlbumInfo((prev) => {
      return { ...prev, currency: currency };
    });
  };

  const updateSong = (data, index) => {
    let newSongs = songs.map((s, i) => {
      if (i === index) {
        return {
          ...data,
          albumArt: albumInfo?.albumArt?.url,
          albumArtRef: albumInfo?.albumArt?.ref,
        };
      } else {
        return s;
      }
    });
    setSongs(newSongs)
    setAlbumInfo((prev) => {
      
      return { ...prev, songs: newSongs };
    });
    setArtists(albumInfo?.artists);
  };

  const updateSongsArtCover = (data) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        songs: prev.songs?.map((s) => {
          return {
            ...s,
            albumArt: data?.albumArt?.url,
            albumArtRef: data?.albumArt?.ref,
          };
        }),
      };
    });
  };

  const openSongInfo = (i) => {
    setOpenSongs((prev) => {
      if (prev.includes(i)) {
        return [...prev];
      } else {
        return [...prev, i];
      }
    });
  };

  const closeSongInfo = (i) => {
    if (
      (albumInfo.songs[i]?.songUpload?.progress > 0 &&
        albumInfo.songs[i]?.songUpload?.progress < 100) ||
      (albumInfo.songs[i]?.songUpload?.progress === 100 &&
        !albumInfo.songs[i]?.songUpload?.url)
    ) {
      addAlert({
        title: "Song Upload",
        type: ALERT_TYPES.WARNING,
        message: "Song still uploading, please wait",
      });
      return;
    } else {
      setOpenSongs((prev) => {
        if (prev.includes(i)) {
          return prev.filter((s) => s !== i);
        } else {
          return [...prev];
        }
      });
    }
  };

  const deleteSong = (i) => {
    toggleModal();
    modalContent(
      <OptionModal heading={`Are you sure you want to remove this track`}>
        <HoverOption
          onClick={() => {
            let s = songs.filter((song, index) => index !== i)
            songs[i]?.songUpload?.url && deleteObject(storageRef(storage,songs[i]?.songUpload?.ref))
            let open = openSongs.filter(song => song !== i)
            setOpenSongs(open)
            setSongs(s);
            toggleModal();
            modalContent();
          }}
        >
          Yes
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          No
        </HoverOption>
      </OptionModal>
    );
  };

  const cancelSong = (data, i) => {
    if (JSON.stringify(albumInfo.songs[i]) !== JSON.stringify(data)) {
      toggleModal();
      modalContent(
        <OptionModal heading={`Are you sure you want to discard changes?`}>
          <HoverOption
            onClick={() => {
              setOpenSongs((prev) => {
                return prev.filter((s) => s !== i);
              });
              toggleModal();
              modalContent();
            }}
          >
            Yes
          </HoverOption>
          <HoverOption
            onClick={() => {
              toggleModal();
              modalContent();
            }}
          >
            No
          </HoverOption>
        </OptionModal>
      );
    } else {
      setOpenSongs((prev) => {
        return prev.filter((s) => s !== i);
      });
    }
  };

  const selectArtist = (type, artist) => {
    let a = [];
    a = artists;
    setCurrentArtist({ ...artist, exists: true });
    a.push({ ...artist, exists: true });
    setArtists(a);
    updateArtists(a);
    setArtistSearch();
    clearArtistForm(type);
  };

  //Add specific artist type to list
  const addArtist = (type) => {
    let a = [];
    a = artists;
    a.push({ ...currentArtist, exists: false });
    setArtists(a);
    updateArtists(a);
    setCurrentArtist();
  };

  const clearArtistForm = (type) => {
    setArtistSearch();
    setCurrentArtist();
    if (artistSearchRef?.current?.value) {
      artistSearchRef.current.value = "";
    }
    if (artistIdRef?.current?.value) {
      artistIdRef.current.value = "";
    }
    if (artistNameRef?.current?.value) {
      artistNameRef.current.value = "";
    }
    if (walletAddressRef?.current?.value) {
      walletAddressRef.current.value = "";
    }
    if (artistFullNameRef.current?.value) {
      artistFullNameRef.current.value = "";
    }
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const validateArtistInfo = (id, type) => {
    if (validateArtistInput(currentArtist)) {
      artistEditing ? editArtist(id, type) : addArtist(type);
    } else {
    }

    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        updateArtists(updateArtists);
        setArtistEditing(false);
        break;

      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];

    a = artists;
    updatedArtists = a.filter((artist, index) => {
      if (index !== id) return artist;
    });
    if (containsObject(albumInfo?.userArtist, updatedArtists)) {
      setArtists(updatedArtists);
    } else {
      setArtists([albumInfo?.userArtist, ...updatedArtists]);
    }
    setArtistEditing(false);
    updateArtists(updateArtists);
    setArtistEditing(false);

    clearArtistForm(type);
  };

  const updateArtists = (data) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        artists: data,
      };
    });
  };

  const addGenre = (musicGenre) => {
    setGenre();

    setAlbumInfo((prev) => {
      return { ...prev, mainGenre: musicGenre };
    });

    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
    setAlbumInfo((prev) => {
      return { ...prev, mainGenre: undefined };
    });
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        if (e.target.value.trim() !== "") {
          delete cArtist?.errors?.name;
          setCurrentArtist({ ...cArtist, artistName: e.target.value });
        } else {
          setCurrentArtist({
            ...cArtist,
            errors: {
              ...cArtist?.errors,
              name: "Artist name cannot be empty",
            },
            artistName: e.target.value,
          });
        }
        AddNEArtistsToSearch(e);
        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        setCurrentArtist({ ...cArtist, fullName: e.target.value });
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        setCurrentArtist({ ...cArtist, artistId: e.target.value });

        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        setCurrentArtist({ ...cArtist, address: e.target.value });
        break;
      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }
    if (e.target.value.length === 0) {
      setArtistSearch();
    }
  };

  const setVisualizerFile = (data) => {
    setAlbumInfo(prev => {
      return {
        ...prev,
        visualizerFile: data
      }
    })
  } 

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [...artists.filter((a) => a.exists)];
    let allNEArtists = [...artists.filter((a) => !a.exists)];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistartistName"),
    ];
    return allArtists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [...artists];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if(filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  return (
    <>
      <MusicTitleInfoContainer>
        <AlbumUploadsContainer
          for={
            albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url
              ? ""
              : "albumArt"
          }
        >
          {albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url && (
            <>
              <div style={{ display: "flex", width: "50px", height: "50px" }}>
                {albumInfo?.albumArt.progress < 100
                  ? `${albumInfo?.albumArt?.progress.toFixed(2)}%`
                  : `Processing`}
              </div>
            </>
          )}
          {(albumInfo?.albumArt?.url || !albumInfo?.albumArt?.progress) && (
            <>
              <MusicUploadAlbumArt
                src={
                  albumInfo?.albumArt?.url
                    ? albumInfo?.albumArt?.url
                    : defaultAlbumArt
                }
                alt={"albumName"}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
              />
            </>
          )}
        </AlbumUploadsContainer>
        <MusicUploadTitleTextWrapper>
          <MusicUploadContentTitle>
            {albumInfo?.albumName ? albumInfo?.albumName : "Album Name"}
          </MusicUploadContentTitle>
          <>
            <MusicUploadInfoText>
              {artists?.length > 0
                ? artists?.map((artist, index) => {
                    if (index === artists.length - 2) {
                      return artist?.artistName + " & ";
                    } else if (index === artists.length - 1) {
                      return artist?.artistName;
                    } else if (index < artists.length - 2) {
                      return artist?.artistName + ", ";
                    }
                  })
                : "Album Artists"}
            </MusicUploadInfoText>
          </>
          <MusicUploadInfoText>
            {albumInfo?.releaseDate
              ? new Date(albumInfo?.releaseDate).toDateString()
              : "Release Date"}
          </MusicUploadInfoText>
          <MusicUploadInfoText>
            {albumInfo?.isDownloadable && <>{albumInfo?.price
              ? `${albumInfo?.price} ${albumInfo?.currency}`
              : "Price"}</>}
          </MusicUploadInfoText>
        </MusicUploadTitleTextWrapper>
        <SongArtCoverWrapper style={{ flex: "1 1 100%" }}>
          <Zoom>
            <SongDetailsSection>
              <SongDetailsSectionTitle>Album Art</SongDetailsSectionTitle>
              <AlbumUploadsContainer
                for={
                  albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url
                    ? ""
                    : "albumArt"
                }
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {albumInfo?.albumArt?.url ? (
                      `${albumInfo?.albumArt.file?.name ? albumInfo?.albumArt.file?.name : albumInfo?.albumArt.name}`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add Album Art (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {albumInfo?.albumArt?.progress > 0 &&
                    !albumInfo?.albumArt?.url && (
                      <SongDetailsText
                        onClick={() => setCancelAlbumArtUpload(true)}
                        style={{ textDecoration: "underline" }}
                      >
                        Cancel
                      </SongDetailsText>
                    )}
                  {albumInfo?.albumArt?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumUploadsInput
                  id="albumArt"
                  type={"file"}
                  name="albumArt"
                  accept="image/x-png,image/gif,image/jpeg, image/jpg"
                  multiple="false"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
              </AlbumUploadsContainer>
            </SongDetailsSection>
          </Zoom>
        </SongArtCoverWrapper>
      </MusicTitleInfoContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "2%",
        }}
      >
        <AlbumDetailsContainer style={{ flexDirection: "column" }}>
          <AlbumDetailsWrapper>
            <FormWrapper>{pageNumber === 1 && (
              <Page1
                albumInfo={albumInfo}
                handleInput={handleInput}
                handleToggle={handleToggle}
                currency={albumInfo?.currency}
                addGenre={addGenre}
                genre={genre}
                filteredGenres={filteredGenres}
                removeGenre={removeGenre}
                setCurrency={setCurrency}
              />
            )}
            {pageNumber === 2 && (
              <Page2
                albumInfo={albumInfo}
                artistEditng={artistEditing}
                walletAddressRef={walletAddressRef}
                artists={artists}
                artistFullNameRef={artists}
                artistIdRef={artistIdRef}
                artistNameRef={artistNameRef}
                validateArtistInfo={validateArtistInfo}
                artistSearchRef={artistSearchRef}
                artistSearch={artistSearch}
                searchInput={searchInput}
                clearArtistForm={clearArtistForm}
                handleArtistInput={handleArtistInput}
                errors={albumInfo?.errors}
                setArtistEditing={setArtistEditing}
                selectArtist={selectArtist}
                removeArtist={removeArtist}
                currentArtist={currentArtist}
                setCurrentArtist={setCurrentArtist}
                handleInput={handleInput}
              />
            )}
            {pageNumber === 3 && (
              <Page3
                visualizerFile={albumInfo?.visualizerFile}
                setVisualizerFile={setVisualizerFile}
                
              />
            )}
            {pageNumber === 4 && (
              <Page4
                license={albumInfo?.license}
                setCreativeCommons={setCreativeCommons}
              />
            )}
             {albumInfo?.isUserRoyalties && pageNumber === 5 && (
              <Page5
                isUserRoyalties={albumInfo?.isUserRoyalties}
                userRoyalties={userRoyalties}
                setUserRoyalties={setUserRoyalties}
                errors={albumInfo?.errors}
                setErrors={(err) => setAlbumInfo(prev => {
                  return {
                    ...prev,
                    errors: err
                  }
                })}
              />
            )}
            </FormWrapper>
          </AlbumDetailsWrapper>
          <PageIndexer
            pages={albumInfo?.isUserRoyalties ? 5 : 4}
            currentPage={pageNumber}
            onClick={setPageNum}
          />
        </AlbumDetailsContainer>
        <MusicUploadDetailsContainer>
          {songs?.map((song, index) => {
              return (
                <SongInfoContainer
                  style={{
                    padding: "1%",
                    borderRadius: "20px",
                    color: themeColors.secondaryBg,
                  }}
                >
                  {index + 1}
                  {!openSongs.includes(index) && (
                    <div style={{ display: "flex" }}>
                      <CloseButton onClick={() => openSongInfo(index)}>
                        Open
                      </CloseButton>
                    </div>
                  )}

                  <div style={{ display: "flex" }}>
                    <SongInfoWrapper
                      style={{ flex: "1 1 100%" }}
                      onClick={() => openSongInfo(index)}
                    >
                      {!openSongs.includes(index) && (
                        <SongInfoTextWrapper style={{ padding: "2%" }}>
                          <MusicUploadContentTitle>
                            {song?.songName
                              ? song?.songName
                              : `Track ${index + 1}`}
                          </MusicUploadContentTitle>
                          <MusicUploadInfoText>
                            <>
                              {song?.artists?.length > 0
                                ? song?.artists.map((artist, index) => {
                                    if (index === song?.artists.length - 2) {
                                      return artist.artistName + " & ";
                                    } else if (
                                      index ===
                                      song?.artists.length - 1
                                    ) {
                                      return artist.artistName;
                                    } else if (
                                      index <=
                                      song?.artists.length - 1
                                    ) {
                                      return artist.artistName + ", ";
                                    }
                                  })
                                : "Artist(s)"}
                            </>
                          </MusicUploadInfoText>
                        </SongInfoTextWrapper>
                      )}
                    </SongInfoWrapper>
                  </div>

                  {openSongs.includes(index) && (
                    <SongDetails
                      id={index}
                      deleteSong={deleteSong}
                      cancelSong={closeSongInfo}
                      song={song}
                      index={index}
                      albumInfo={albumInfo}
                      setAlbumSong={updateSong}
                      songUploadTasks={songUploadTasks}
                      setSongUploadTasks={setSongUploadTasks}
                    />
                  )}
                </SongInfoContainer>
              );
            })}
          {openSongs?.length === 0 && <SongInfoContainer
            style={{
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              color: themeColors.secondaryBg,
              borderRadius: "15px",
              padding: "2%",
              background: hexToRgba(themeColors.secondaryColor, 0.2),
            }}
            onClick={addTrack}
          >
            Add Track
          </SongInfoContainer>}
        </MusicUploadDetailsContainer>
      </div>
      {albumInfo?.errors?.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{albumInfo?.errors?.message}</SongDetailsText>
        </ErrorWrapper>
      )}      <MusicUploadsButtonContainer>
        {!submitting && <MusicUploadsButton outline={!hideButtons} onClick={() => hideButtons ? {} : releaseAlbum("draft")}>
          Save Draft
        </MusicUploadsButton>}
        {!submitting && <MusicUploadsButton outline={!hideButtons} onClick={() => hideButtons ? {} : releaseAlbum("publish")}>
          Release Album
        </MusicUploadsButton>}
        {submitting  && <MusicUploadsButton outline onClick={() => goTo(`/album/${albumId}`)}>
          View Release
        </MusicUploadsButton>}
       {!submitting &&  <MusicUploadsButton outline onClick={() => cancelMusicUpload()}>Cancel</MusicUploadsButton> }
      </MusicUploadsButtonContainer>
    </>
  );
}

export default AlbumUploads;
